.main {
  margin: 0px;
  color: rgb(39, 37, 37);
}

.main__title {
  text-align: center;
  color: rgb(39, 37, 37);
}

.fabula {
  padding: 1px 30px 30px 30px;


}

.fabula__paragraph {
  text-indent: 2em;
}

.fabula__title {
  text-align: center;
  margin-bottom: 15px;
}

.fabula__responsive {
  width: 100%;
  border-radius: 7px;
  margin: 10px 0px;
}

.fabula__author {
  font-size: 12px;
}