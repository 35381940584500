/* Style Page */
.menu {
  font-size: 1em;
  text-transform: capitalize;
  box-shadow: 0px 2px 10px 1px rgba(255, 138, 165, 0.8)
}

.menu__logo {
  margin-left: 20px;
  margin-top: 10px;
  align-self: center;
  width: 40px;
  height: 40px;
}

.menu__nav {
  display: flex;
  justify-content: space-between;
}

/* Component Menu */
.menu__list {
  display: flex;
  list-style: none;
  margin-top: 10px;
  margin-right: 10px;
}

.menu__item {
  height: 30px;
  overflow: hidden;
  margin-bottom: 20px;
}

.menu__action {
  border-radius: 5px;
  padding: 5px 15px;
  text-decoration: none;
  color: var(--corb4);
  transition-property: transform;
  transition-timing-function: ease-out;
  transition-duration: 300ms;

  display: flex;
  flex-direction: column;

}

.menu__action:hover {
  text-decoration: underline;
  transform: translateY(-50%);
}

/* CRIAR HAMBURGUER  */
.btn__menu {
  display: none;
  cursor: pointer;
  padding: 15px 5px;
  border-radius: 3px;
  align-items: center;

}

.btn__menu:hover .btn__bar {
  background-color: var(--corb2);
}

.btn__bar {
  display: block;
  width: 28px;
  height: 3px;
  margin: 5px auto;
  background-color: var(--corb4);
}

/* LAYOUT DE DESKTOP */
/* Menu aplicamos a tecnica Desktop For Mobile - DFM 
Desktop big - ok 

Desktop small - 
@media screen and (max-width:1200px) 
    Tablet
@media screen and (max-width:769px) 
    Mobile Medium
@media screen and (max-width:481px) 
    Mobile small
@media screen and (max-width:320px) 
*/
/* Desktop small  */
@media(max-width: 1200px) {

  /* AJUSTAR TAMANHO DA FONTE PARA CONTER NA TELA */
  .menu {
    font-size: 1em;
  }

}


/* Lógica esconder Menu desktop e mostrar icones de Mobile */
@media (max-width: 769px) {

  /* MENU FECHAR */
  .menu__nav {
    display: flex;
    font-size: 2em;
    gap: 0;
    margin: 0;
  }

  .btn__menu {
    margin-right: 20px;
    display: block;
    cursor: pointer;
  }

  .btn__menu.active .btn__bar:nth-child(2) {
    opacity: 0;
  }

  .btn__menu.active .btn__bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .btn__menu.active .btn__bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .menu__list {
    position: fixed;
    left: 0px;
    top: -100%;
    background: var(--corb2);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    gap: 0px;
    width: 100%;
    transition: 600ms ease-in-out;
  }

  .menu__item {
    border-bottom: var(--corb3) 1px solid;
    height: 100%;
    margin-bottom: 0px;
  }

  .menu__action {
    text-align: center;
    background-color: var(--corb2);
  }

  .menu__action:hover {
    text-decoration: underline;
    transform: none;
  }

  .menu__list.active {
    top: 50px;
    z-index: 20;
  }

  .item__show {
    display: none;
  }
}