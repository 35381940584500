.sobre-nos {
    padding: 20px;
}

.sobre__title {
    text-align: center;
}

.sobre__paraf {
    text-indent: 2em;
}