.central {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url(./varios-itens.jpeg);
}

.miniCentral {
    color: var(--corb4);
    background-color: var(--corb3);
    padding: 10px 30px;
    text-align: center;
    border-radius: 15px;
    opacity: .85;
}

.btnCentral {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px;
    text-decoration: none;
    background-color: var(--corb4);
    color: white;
    border-radius: 15px;
    font-size: 1.6em;
}

.btnCentral:hover {
    background-color: var(--corb7);
}

@media (max-width: 1028px) {
    .mini-central {
        left: 10%;
        width: 70%;
    }

}

@media (max-width: 600px) {
    .mini-central {
        left: 17%;
        width: 70%;
    }

    .titleCentral {
        font-size: 1em;
    }

    .btnCentral {
        font-size: 1.2em;
    }

}