.rodape {
  display: flex;
  justify-content: space-between;
  color: #000;
  padding: 0px 30px;
}

.menu-s {
  flex-basis: 50%;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}

.menu__title {
  margin-left: -10px;
  color: rgb(51, 41, 41);
  font-size: 1em;
}

.menu-s__list {
  list-style: none;
}

.menu-s__action {
  padding: 2px;
  margin: 2px 2px 2px -10px;
  color: var(--corb4);
  text-decoration: none;
}

.menu-s__action:hover {
  border-radius: 5px;
  text-decoration: underline;
}

.rodape__title {
  text-align: center;
  margin: 10px;
}

.rodape__list {
  flex-basis: 50%;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
}

.rodape__insta {
  width: 32px;
  border-radius: 14px;
}

.rodape__face {
  width: 32px;
  height: 32px;
  border-radius: 12px;
}

.rodape__whats {
  margin-top: 4px;
  width: 35px;
  height: 38px;
  border-radius: 12px;
}


.rodape__item {
  padding: 5px 5px 2px 5px;
}

.rodape__item:hover {
  background-color: var(--corb4);
  border-radius: 10px;
}

.rodape__copyright {
  flex-basis: 50%;
  margin: 5px;
  text-align: center;
  font-size: 0.6em;
}

@media screen and (max-width:576px) {

  .rodape {
    font-size: 1em;
    flex-direction: column;
    align-items: center;
  }

  .menu-s {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1em;
    gap: 0;
  }

  .menu-s__list {
    margin: 20px;
  }
}