.home {
  padding: 0;
  margin: 0;
}

.home-title {
  padding: 20px;
  color: var(--corb4);
}

.palco {
  height: 100vh;
}

.palco-2 {
  height: 100vh;
  background-color: var(--corb2);
  padding: 0 20px;
}

.home-contato {
  background-color: var(--corb2);
  padding: 20px;
}

.home-contato h1 {
  text-align: center;
}

.home-contato .menu {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  list-style-type: none;
  font-weight: bold;
}

.home-contato .menu-action {
  text-decoration: none;
  color: white;
  background-color: var(--corb4);
  padding: 10px;
  border-radius: 5px;
}

.home-contato .menu-action:hover {
  background-color: var(--corb7);
}


.encomenda-h3 {
  display: none;
}

.home-blog {
  padding: 20px;
}

.home-blog h1 {
  text-align: center;
}

.home-encomenda {
  background-color: var(--corb2);
  padding: 20px 20px 200px 20px;
  position: relative;
}

.home-encomenda h1 {
  text-align: center;
}

.btnSaiba {
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  background-color: var(--corb4);
  color: white;
  font-size: 1.5em;
  border-radius: 10px;
  width: 500px;
  max-width: 500px;

}

.btnSaiba:hover {
  background-color: var(--corb7);
}

.img-responsive {
  width: 100%;
}

.p-fabula {
  margin-bottom: 15px;
}

.artigo-secondary {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}



@media (max-width:599px) {

  .home-encomenda,
  .home-fabula,
  .home-contato {
    padding: 20px 10px 100px 10px;
  }

  .btnSaiba {
    width: 250px;
  }
}

@media screen and (min-width:600px) {

  .home-encomenda,
  .home-blog,
  .home-contato {
    padding: 50px 120px;
  }
}

@media screen and (min-width:920px) {

  .home-encomenda,
  .home-blog,
  .home-contato {
    padding: 80px 160px;
  }
}

@media screen and (min-width:1200px) {

  .home-encomenda,
  .home-blog,
  .home-contato {
    padding: 100px 200px;

  }
}