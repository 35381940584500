.atelie {
  padding: 20px;
  display: flex;
  flex-flow: column wrap;
}

.category .menuList {
  display: flex;
  list-style: none;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 3px;
  column-gap: 5px;
  padding: 10px;
  justify-content: flex-start;
  align-items: stretch;
}

.category .menuItem {
  background-color: var(--corb5);
  border-radius: 3px;
  display: flex;
  align-items: stretch;
}

.category .menuItem:nth-child(1) {
  color: var(--corb4);
  background-color: var(--corb3);
  font-size: 1.5em;
  width: 100%;

}

.category .menuAction {
  text-decoration: none;
  color: white;
  font-size: 1.5em;
  padding: 5px 10px;
  width: 100%;
}

.category .menuAction:hover {
  background: var(--corb7);
  color: white;
}

.atelie .titlePrimary {
  color: var(--corb4);
  font-size: 1.4em;
}

.title-atelie {
  font-size: 1em;
}

.atelie .titleSecondary {
  padding-left: 10px;
  color: var(--corb5);
}

@media screen and (max-width:500px) {
  .category {
    font-size: 0.6em;
  }

  .atelie .titlePrimary {
    font-size: 0.6em;
  }
}

@media screen and (min-width:501px) {
  .atelie .titlePrimary {
    font-size: 1em;
  }

  .atelie-main {
    display: flex;
    gap: 10px;
    flex-flow: row wrap;
  }
}